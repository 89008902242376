import React from "react"

import BlockContent from "~components/blockContent/blockContent"
import ImageGrid from "~components/imageGrid/imageGrid"
import Slideshow from "~components/slideshow/slideshow"

import "./blockMatrix.scss"


const BlockMatrix = ({ blocks }) => {
	return (<section className="block_matrix">
			{ blocks.map((b, i) => {

				if(b._type === 'aboutPanel'){
					return <section key={'block_' + i} className="text_container matrix_text block_matrix_block">
						{b.title && <h1 className="font-size--small centered">{b.title}</h1>}
						<BlockContent blocks={b._rawCopy} />
					</section>
				}else{
					return <section key={'block_' + i} className="block_matrix_block" >
						{b.images.length > 0 ? (
	            <div className="desktop-only"><ImageGrid images={b.images} /></div>
	          ) : null}

	          {b.images.length > 0 ? (
	            <div className="mobile-only"><Slideshow slideshow={b} /></div>
	          ) : null}

					</section>
				}
				}) 
			}
	</section>)
}

export default BlockMatrix