import React, { useContext, useRef } from "react"
import { Fade, SlideshowRef } from "react-slideshow-image"

/* Import Global Context(s) */
import IntroContext from "~context/intro"

/* Import Global Component(s) */
import Image from "~components/image/image"
import YoutubeEmbed from "~components/youtube/youtube"

/* Import SLideshow Style(s) */
import "react-slideshow-image/dist/styles.css"

/* Import Local Style(s) */
import "./slideshow.scss"

const Slideshow = ({ slideshow: { images, delay }, pauseOnHover = false, hasVideo = false }) => {
  const [isIntroDone] = useContext(IntroContext)
  const slidesCount = images.length

  const slideRef = useRef(null)


  console.log(images)

  return slidesCount > 1 ? (
    <div className="slideshow">
      <Fade
        duration={delay * 1000}
        transitionDuration={150}
        arrows={false}
        pauseOnHover={pauseOnHover}
        canSwipe={false}
        autoplay={isIntroDone && !hasVideo}
        ref={slideRef}
      >
        {images.map((image, index) => (
          (image.asset ? <div className="each-fade" key={image.asset.id}>
            <div className="image-container">
              {<Image image={image.asset.gatsbyImageData} />}
              {/* <img src={url} alt="" /> */}
            </div>
          </div>
          : image.youtubeOverride ? <div className="each-fade youtube-fade" key={'youtube_' + index}>
            <div className="image-container">
              <YoutubeEmbed embed={image.youtubeOverride} />
            </div>
          </div>
          : null
          )
        ))}
      </Fade>

      {hasVideo && <nav>
        <button className="slideshow-nav font-size--small" onClick={() => slideRef.current.goBack()}>Previous</button>, <button 
          className="slideshow-nav font-size--small"
          onClick={() => slideRef.current.goNext()}
        >Next</button>
      </nav>}

    </div>
  ) : (images[0].asset !== undefined && images[0].asset !== null) ? (
    <div className="slideshow single-image">
      <Image image={images[0].asset.gatsbyImageData} />
    </div>
  ) : ''
}

export default Slideshow
